import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useInfraStatus, useInfraTest } from 'apiHooks/wmsHooks';
import Button from 'components/inputs/ButtonM';
import React, { useState } from 'react';
import Select from 'react-select';
import { useBranchId } from 'utils/customHooks';
import { formatDateTime } from 'utils/utils';

export default function InfraLight() {
  const branchId = useBranchId();
  const [stationCode, setStationCode] = useState();
  const [selectedTest, setSelectedTest] = useState();
  const [getStatus, setGetStatus] = useState(false);
  const { data: infraStatus, refetch, status, isFetching } = useInfraStatus({ branchId });
  const [infraTest, { status: mutationStatus }] = useInfraTest({ branchId });

  const testOptions = [
    {
      label: 'test',
      value: 'test',
    },
    {
      label: 'reset',
      value: 'reset',
    },
    {
      label: 'identify',
      value: 'identify',
    },
    {
      label: 'zone_bin_sequence',
      value: 'zone_bin_sequence',
    },
  ];
  const stationCodes = infraStatus?.reduce((a, i) => a.add(i.station), new Set()) || new Set();
  const stationCodeOptions = [...stationCodes].map((s) => ({ label: s, value: s }));

  const isInDone = (c, idx) => c.metadata.state.done.includes(idx);
  const submit = () => {
    infraTest({
      stationCode: stationCode?.value || '',
      test: selectedTest?.value || '',
    });
  };

  const onGetStatus = () => {
    getStatus && refetch();
    setGetStatus(true);
  };

  return (
    <div className="h-screen">
      {isFetching || status === 'loading' || mutationStatus === 'loading' ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}

      <div className="flex flex-col w-full p-5 justify-center items-center">
        <div className="flex flex-1">
          <FormControl required>
            <Select
              className="z-20 w-56 mx-10"
              placeholder="Station Code"
              value={stationCode}
              options={stationCodeOptions}
              onChange={setStationCode}
            />
          </FormControl>
          <FormControl required>
            <Select
              className="z-20 w-44 mx-10"
              placeholder="Test"
              value={selectedTest}
              options={testOptions}
              onChange={setSelectedTest}
            />
          </FormControl>
          <Button color="primary" variant="outlined" onClick={submit}>
            Submit
          </Button>
        </div>
        <div className="mt-10">
          <Button color="primary" variant="contained" onClick={onGetStatus}>
            Get Status
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 mx-10">
        {getStatus &&
          infraStatus?.map((s) => (
            <div key={s.station} className="bg-white rounded-md py-3 px-2 flex-shrink-0">
              <div className="flex flex-1 flex-col">
                <p className="text-center mb-4 text-base">{s.station}</p>
                <div className={`grid grid-cols-${s.ctrls.length || 1} gap-3`}>
                  {s.ctrls.map((c) => (
                    <div key={c.id}>
                      <ItemWithLabel label={'Device'} value={c.device} />
                      <ItemWithLabel label={'Online'} value={`${c.online}`} />
                      <ItemWithLabel
                        label={'Time Stamp'}
                        value={formatDateTime(new Date(c.timestamp))}
                      />
                      <ItemWithLabel label={'Slot Start'} value={c.slotStart} />
                      <ItemWithLabel label={'Slot End'} value={c.slotEnd} />
                      <p className="text-primary">Metadata State: </p>
                      <div className="grid grid-cols-3 lg:grid-cols-6 gap-1 my-3">
                        {new Array(18).fill(1).map((_, idx) => (
                          <div
                            key={idx}
                            className={`${
                              isInDone(c, idx) ? 'bg-success-green' : 'bg-gray-200'
                            } py-1 rounded-md`}
                          >
                            <ItemWithLabel
                              isComplete={isInDone(c, idx)}
                              textAlign="text-center"
                              label={`${idx + 1}`}
                              value={c.metadata.state[idx] || '__'}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

const ItemWithLabel = ({ label, value, isComplete, textAlign }) => {
  return (
    <div className={textAlign || 'text-left'}>
      <p className={isComplete ? 'text-white' : 'text-issue'}>
        <span className={isComplete ? 'text-white' : 'text-primary'}>{label}</span> : {value}
      </p>
    </div>
  );
};
