import Button from 'components/inputs/ButtonM';
import { navigate } from 'components/Link';
import React from 'react';

export default function INFRA() {
  return (
    <div>
      <div className="flex flex-col w-full h-full p-5">
        <div className="flex flex-row mt-5 mx-5 justify-center items-center">
          <div className="flex-1">
            <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold">
              INFRA
            </p>
          </div>
        </div>
        <div className="flex flex-1 mt-10 justify-start items-start">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('wms/INFRA/infra')}
          >
            Infra Lights
          </Button>
        </div>
      </div>
    </div>
  );
}
