import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import INFRA from 'wms/INFRA/index';
import InfraLight from 'wms/INFRA/infra';

export default function MHEsContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <div class="bg-background h-full">
          <Router>
            <INFRA path="/wms/INFRA" />
            <InfraLight path="/wms/INFRA/infra" />
          </Router>
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
